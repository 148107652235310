import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const PC = styled.div`
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
  display: flex;
  border-top: 2px solid ${GREYS.white};
  padding: 2em;
  @media (max-width: ${BREAKPOINT_S}) {
    position: absolute;
    bottom: 0px;
    z-index: 9999;
    flex-direction: column;
  }
`;

export const T = styled.div`
  flex: 3;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-left: 0px;
  }
`;

export const B = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0px 8px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px 0px 0px 0px;
  }
`;

export const RB = styled.span`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  border-radius: 4px;
  background-color: ${FONT.secondary};
  color: ${GREYS.black};
  font-weight: 800;
  &:hover {
    background-color: ${BRAND.secondaryD};
  }
  @media (max-width: ${BREAKPOINT_S}) {
    color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
  }
`;
