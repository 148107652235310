import React from 'react';
import PropTypes from 'prop-types';
import { PopupContent } from './components/popupContent';
import { getCookie } from 'Services/cookie/core__cookies';
import { storeIsAccepted } from '../core__cookiePopup-store';
/**
 * component holds login logic, renders pin based auth if pinLogin=true
 */
class CookiePopup extends React.Component {
  constructor(props) {
    super(props);
    const isAccepted = getCookie('cookieAcceptance');
    this.props.dispatch(storeIsAccepted(isAccepted));
    this.state = {
      render: false,
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    const {
      text,
      isbetgtmtag,
      istranslated,
      havelinkpolicy,
    } = this.props.appConfig;
    const { dispatch, isAccepted } = this.props;

    if (this.state.render) {
      return (
        <PopupContent
          text={text}
          dispatch={dispatch}
          isAccepted={isAccepted}
          isbetgtmtag={isbetgtmtag}
          isTranslated={istranslated}
          haveLinkPolicy={havelinkpolicy}
        />
      );
    } else {
      return null;
    }
  }
}

CookiePopup.propTypes = {
  dispatch: PropTypes.func,
  text: PropTypes.string,
  appConfig: PropTypes.object,
  isbetgtmtag: PropTypes.bool,
  isAccepted: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  istranslated: PropTypes.bool,
};

export default CookiePopup;
