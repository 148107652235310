import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider, connect } from 'react-redux';
import ScDecoder from 'Services/json/core__decoder';
import createStore from './core__cookiePopup-store';
import CookiePopupReact from './component/core__cookiePopupReact';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider('CookiePopupApp');

class CookiePopupApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );
    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = state => {
      return {
        appConfig: this.appConfig,
        isAccepted: state.data.isAccepted,
      };
    };

    const mapDispatchToProps = dispatch => ({
      dispatch,
    });

    CookiePopupReact.serverFetch = {
      url: this.url,
      createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      strToTranslate: ['text.accept', 'text.cookie.popup.content'],
    };
    return connect(mapStateToProps, mapDispatchToProps)(CookiePopupReact);
  }
}

export default CookiePopupApp;
