import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { createCookie } from 'Services/cookie/core__cookies';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';

const initialStateTemplate = {
  data: {
    isAccepted: false,
  },
};

/**
 * This function is used to return the action for store event data.
 */
export const storeIsAccepted = isAccepted => {
  return {
    type: 'STORE_IS_ACCEPT',
    isAccepted,
  };
};

export const cookiePopupReducer = (state = [], action) => {
  switch (action.type) {
    case 'STORE_IS_ACCEPT':
      return { ...state, isAccepted: action.isAccepted };
    default:
      return state;
  }
};
export const reducer = combineReducers({
  data: cookiePopupReducer,
});

export const cookieAccept = cookieAccept => dispatch => {
  createCookie('cookieAcceptance', true);
  if (cookieAccept) {
    PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
      event: TRACKING_CONSTANTS.COOKIE_CONSENT,
      data: {},
    });
  }
  dispatch(storeIsAccepted(true));
};

const composeEnhancers = getComposeEnhancers({ name: 'cookie-popup-store' });

export default initialState => {
  initialState = Object.assign(initialStateTemplate, initialState);
  return createStore(
    reducer,
    initialState ? initialState : initialStateTemplate,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
};
