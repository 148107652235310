import React from 'react';
import PropTypes from 'prop-types';
import { PC, T, B, RB } from 'UI/apps/CookiePopupApp/PopupContent';
import { cookieAccept } from '../../../core__cookiePopup-store';
import { Translations } from '../../../core__cookiePopup-app';

export const PopupContent = ({
  text,
  dispatch,
  isAccepted,
  isbetgtmtag,
  isTranslated,
  haveLinkPolicy,
}) => {
  return (
    <React.Fragment>
      {!isAccepted && (
        <PC>
          <T>
            {isTranslated ? (
              Translations.get(text)
            ) : haveLinkPolicy ? (
              <div dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              text
            )}
          </T>
          <B>
            <RB
              onClick={() => {
                dispatch(cookieAccept(isbetgtmtag));
              }}
            >
              {Translations.get('text.accept')}
            </RB>
          </B>
        </PC>
      )}
    </React.Fragment>
  );
};

PopupContent.propTypes = {
  dispatch: PropTypes.func,
  isAccepted: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  text: PropTypes.string,
  isbetgtmtag: PropTypes.bool,
  isTranslated: PropTypes.bool,
  haveLinkPolicy: PropTypes.bool,
};
